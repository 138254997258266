import React from 'react';

const TestimonialSectionWithoutBG = (props) => {
    
    return (
        <section className={"testimonial-section " + props.extraClasses}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-20">
                        <div className="row">
                            <div className="col-xl-18">
                                <h2 className="h1 section-title extra-spacing">{props.mainTitle}</h2>
                            </div>
                        </div>
                        <div className="message">
                            <p>{props.message}</p>
                        </div>
                        <div className="user-detail">
                            <div className="user">
                                <div className="img">
                                    <img
                                        src={props.headshot1x?.sourceUrl}
                                        srcSet={props.headshot2x?.sourceUrl + " 2x, " + props.headshot1x?.sourceUrl + " 1x"}
                                        width={props.headshot1x?.width}
                                        alt={props.headshot1x?.altText}
                                    />
                                </div>
                                <div className="name">
                                    <p className="small semibold">{props.name}</p>
                                    <p className="extra-small">{props.designation}</p>
                                </div>
                            </div>
                            <div className="company-logo">
                                <img
                                    src={props.logo?.sourceUrl}
                                    width={props.logo?.width}
                                    alt={props.logo?.altText}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
        </section> 
    )
}

export default TestimonialSectionWithoutBG